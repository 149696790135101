import React from "react";
import classname from "classnames";
import { Collapse } from "reactstrap";
import styled from "styled-components";

import { StyledSidebarMenuPropsType, MenuItemsType } from ".";
import { COLORS } from "./colors";
import { ArrowDownIcon } from "../UiconsSvgIcons/ArrowDownIcon";
import { ArrowUpIcon } from "../UiconsSvgIcons/ArrowUpIcons";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;

type MenuItemType = {
    index: number;
} & WrapperPropsType &
    MenuItemsType;

const Wrapper = styled.div<WrapperPropsType>`
    .collapsed__clz {
        display: none;
        z-index: 1;
        position: fixed;
        margin-left: 220px !important;
        min-width: 220px;
        max-width: 270px;
        margin-top: -43px !important;
        background-color: ${({ backgroundColor }: WrapperPropsType) => {
            return backgroundColor ? backgroundColor : COLORS.principal;
        }};
    }
    &:hover .collapsed__clz {
        display: block;
    }
`;

export function Menu({
    link,
    menuItem,
    subMenu,
    icon,
    index,
    collapsed,
    backgroundColor,
}: MenuItemType) {
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] =
        React.useState<boolean>(false);
    const [isMenuCollapsed, setIsMenuCollapsed] =
        React.useState<boolean>(false);

    const Icon: any = icon;

    return (
        <li>
            <a
                onClick={() => {
                    setIsSubMenuCollapsed(!isSubMenuCollapsed);
                }}
                href={subMenu !== undefined ? undefined : link}
                className={`${classname({
                    "has-arrow": subMenu !== undefined,
                })} d-flex align-items-center justify-content-between menu-item__clz`}
            >
                <div>
                    {icon ? (
                        <Icon
                            style={{
                                marginRight: "7px",
                                height: "1.25em",
                                width: "33px",
                            }}
                        />
                    ) : null}
                    <span
                        className={`${classname({
                            "d-none": collapsed === true,
                        })}`}
                    >
                        {menuItem}
                    </span>
                </div>

                {collapsed === true ||
                subMenu === undefined ? null : isSubMenuCollapsed === true ? (
                    <ArrowDownIcon />
                ) : (
                    <ArrowUpIcon />
                )}
            </a>

            {collapsed === true ? (
                <ul className="collapsed-submenu__clz">
                    {subMenu !== undefined ? (
                        <li
                            key={index}
                            className="menuitem-collapsed-header__clz"
                        >
                            <span>{menuItem}</span>
                        </li>
                    ) : (
                        <li
                            key={index}
                            style={{ padding: "0px" }}
                            className="menuitem-collapsed-header__clz"
                        >
                            <a href={link}>{menuItem}</a>
                        </li>
                    )}

                    {subMenu !== undefined
                        ? subMenu?.map(
                              (
                                  { link, menuItem, subMenu }: MenuItemType,
                                  index: number
                              ) => {
                                  return (
                                      <Wrapper
                                          key={index}
                                          backgroundColor={backgroundColor}
                                      >
                                          <li className="collapsed-sub-submenu__clz">
                                              <a href={link}>{menuItem}</a>
                                          </li>
                                          {subMenu !== undefined ? (
                                              <ul className="collapsed__clz">
                                                  <li
                                                      key={index}
                                                      className="menuitem-collapsed-header__clz"
                                                  >
                                                      <span>{menuItem}</span>
                                                  </li>
                                                  {subMenu?.map(
                                                      (
                                                          {
                                                              link,
                                                              menuItem,
                                                          }: MenuItemType,
                                                          index: number
                                                      ) => {
                                                          return (
                                                              <li key={index}>
                                                                  <a
                                                                      href={
                                                                          link
                                                                      }
                                                                  >
                                                                      {menuItem}
                                                                  </a>
                                                              </li>
                                                          );
                                                      }
                                                  )}
                                              </ul>
                                          ) : null}
                                      </Wrapper>
                                  );
                              }
                          )
                        : null}
                </ul>
            ) : null}
            {subMenu !== undefined && collapsed !== true ? (
                <Collapse isOpen={isSubMenuCollapsed}>
                    <ul>
                        {subMenu?.map(
                            (
                                { link, menuItem, icon, subMenu }: MenuItemType,
                                index: number
                            ) => {
                                const Icon: any = icon;
                                return (
                                    <li key={index}>
                                        <a
                                            onClick={() => {
                                                setIsMenuCollapsed(
                                                    !isMenuCollapsed
                                                );
                                            }}
                                            href={
                                                subMenu !== undefined
                                                    ? undefined
                                                    : link
                                            }
                                            className="d-flex align-items-center justify-content-between"
                                            style={{
                                                padding:
                                                    ".4rem 1.5rem .4rem 3.5rem",
                                            }}
                                        >
                                            {icon ? (
                                                <Icon
                                                    style={{
                                                        marginRight: "7px",
                                                        height: "1.25em",
                                                        width: "1.25em",
                                                    }}
                                                />
                                            ) : null}
                                            {menuItem}
                                            {subMenu ===
                                            undefined ? null : isMenuCollapsed ===
                                              true ? (
                                                <ArrowDownIcon />
                                            ) : (
                                                <ArrowUpIcon />
                                            )}
                                        </a>

                                        {subMenu !== undefined ? (
                                            <Collapse isOpen={isMenuCollapsed}>
                                                <ul>
                                                    {subMenu?.map(
                                                        (
                                                            {
                                                                link,
                                                                menuItem,
                                                                icon,
                                                                subMenu,
                                                            }: MenuItemType,
                                                            index: number
                                                        ) => {
                                                            const Icon: any =
                                                                icon;
                                                            return (
                                                                <li key={index}>
                                                                    <a
                                                                        href={
                                                                            link
                                                                        }
                                                                        className="d-flex align-items-center justify-content-between"
                                                                        style={{
                                                                            padding:
                                                                                ".4rem 1.5rem .4rem 4.5rem",
                                                                        }}
                                                                    >
                                                                        {icon ? (
                                                                            <Icon
                                                                                style={{
                                                                                    marginRight:
                                                                                        "7px",
                                                                                    height: "1.25em",
                                                                                    width: "1.25em",
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                        {
                                                                            menuItem
                                                                        }
                                                                        {subMenu ===
                                                                        undefined ? null : isSubMenuCollapsed ===
                                                                          true ? (
                                                                            <ArrowDownIcon />
                                                                        ) : (
                                                                            <ArrowUpIcon />
                                                                        )}
                                                                    </a>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </Collapse>
                                        ) : null}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </Collapse>
            ) : null}
        </li>
    );
}
