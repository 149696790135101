import React from "react";
import styled from "styled-components";
import { InputProps } from "reactstrap";

import { SearchIcon } from "../..";

import "bootstrap/dist/css/bootstrap.min.css";

export type StyledSearchInputPropsType = {
    placeholder: string;
} & InputProps;

const Wrapper = styled.div<StyledSearchInputPropsType>`
    display: flex;
    align-items: center;
    position: relative;

    .form-control {
        border: none;
        padding-left: 40px;
        padding-right: 20px;
        background-color: #f3f3f9;
        border-radius: 10px;
    }
    .search-icon__clz {
        position: absolute;
        z-index: 10;
        left: 10px;
        fill: #74788d;
    }
`;

export function StyledSearchInput({
    placeholder,
    ...props
}: StyledSearchInputPropsType) {
    return (
        <Wrapper {...props}>
            <input
                type="text"
                className="form-control"
                placeholder={placeholder}
            />
            <SearchIcon className="search-icon__clz" height={15} width={15} />
        </Wrapper>
    );
}
