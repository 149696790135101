import { PlusIcon } from "../UiconsSvgIcons/PlusIcon";
import { DiskIcon } from "../UiconsSvgIcons/DiskIcon";
import { CheckIcon } from "../UiconsSvgIcons/CheckIcon";
import { EyeIcon } from "../UiconsSvgIcons/EyeIcon";
import { DuplicateIcon } from "../UiconsSvgIcons/DuplicateIcon";
import { PencilIcon } from "../UiconsSvgIcons/PencilIcon";
import { SignInAltIcon } from "../UiconsSvgIcons/SignInAltIcon";
import { CheckboxIcon } from "../UiconsSvgIcons/CheckboxIcon";
import { DocumentIcon } from "../UiconsSvgIcons/DocumentIcon";
import { RedoIcon } from "../UiconsSvgIcons/RedoIcon";
import { SettingsIcon } from "../UiconsSvgIcons/SettingsIcon";
import { FilterIcon } from "../UiconsSvgIcons/FilterIcon";
import { ShieldExclamationIcon } from "../UiconsSvgIcons/ShieldExclamationIcon";
import { ExclamationIcon } from "../UiconsSvgIcons/ExclamationIcon";
import { InfoIcon } from "../UiconsSvgIcons/InfoIcon";
import { CursorFingerIcon } from "../UiconsSvgIcons/CursorFingerIcon";
import { PictureIcon } from "../UiconsSvgIcons/PictureIcon";
import { ExpandIcon } from "../UiconsSvgIcons/ExpandIcon";
import { BellIcon } from "../UiconsSvgIcons/BellIcon";
import { CloudIcon } from "../UiconsSvgIcons/CloudIcon";
import { InboxIcon } from "../UiconsSvgIcons/InboxIcon";
import { HelpIcon } from "../UiconsSvgIcons/HelpIcon";
import { AddFolderIcon } from "../UiconsSvgIcons/AddFolderIcon";
import { FolderIcon } from "../UiconsSvgIcons/FolderIcon";
import { SearchIcon } from "../UiconsSvgIcons/SearchIcon";
import { SquareIcon } from "../UiconsSvgIcons/SquareIcon";
import { UndoIcon } from "../UiconsSvgIcons/UndoIcon";
import { AngleSmallRightIcon } from "../UiconsSvgIcons/AngleSmallRightIcon";
import { AngleDoubleSmallRightIcon } from "../UiconsSvgIcons/AngleDoubleSmallRightIcon";
import { AngleDoubleSmallLeftIcon } from "../UiconsSvgIcons/AngleDoubleSmallLeftIcon";
import { AngleSmallLeftIcon } from "../UiconsSvgIcons/AngleSmallLeftIcon";
import { ShoppingCartIcon } from "../UiconsSvgIcons/ShoppingCartIcon";
import { ShopIcon } from "../UiconsSvgIcons/ShopIcon";
import { PortraitIcon } from "../UiconsSvgIcons/PortraitIcon";
import { BoomIcon } from "../UiconsSvgIcons/BoomIcon";
import { GridIcon } from "../UiconsSvgIcons/GridIcon";
import { RefreshIcon } from "../UiconsSvgIcons/RefreshIcon";
import { MenuBurgerIcon } from "../UiconsSvgIcons/MenuBurgerIcon";
import { VerticalDotsIcon } from "../UiconsSvgIcons/VerticalDotsIcon";
import { DotsMenuIcon } from "../UiconsSvgIcons/DotsMenuIcon";
import { LinkIcon } from "../UiconsSvgIcons/LinkIcon";
import { ArrowLeftIcon } from "../UiconsSvgIcons/ArrowLeftIcon";
import { FolderAddIcon } from "../UiconsSvgIcons/FolderAddIcon";
import { EyeCrossedIcon } from "../UiconsSvgIcons/EyeCrossedIcon";
import { TrashIcon } from "../UiconsSvgIcons/TrashIcon";
import { MinusIcon } from "../UiconsSvgIcons/MinusIcon";
import { LockIcon } from "../UiconsSvgIcons/LockIcon";
import { CrossIcon } from "../UiconsSvgIcons/CrossIcon";
import { CrossCircleIcon } from "../UiconsSvgIcons/CrossCircleIcon";
import { SignInIcon } from "../UiconsSvgIcons/SignInIcon";
import { DownloadIcon } from "../UiconsSvgIcons/DownloadIcon";
import { EditIcon } from "../UiconsSvgIcons/EditIcon";

export const ICONS: { [key: string]: React.ReactNode } = {
    EditIcon,
    CheckIcon,
    CheckboxIcon,
    DiskIcon,
    DocumentIcon,
    DownloadIcon,
    DuplicateIcon,
    EyeIcon,
    FilterIcon,
    PencilIcon,
    PlusIcon,
    RedoIcon,
    SettingsIcon,
    SignInIcon,
    SignInAltIcon,
    CrossCircleIcon,
    CrossIcon,
    LockIcon,
    MinusIcon,
    TrashIcon,
    EyeCrossedIcon,
    FolderAddIcon,
    ArrowLeftIcon,
    LinkIcon,
    DotsMenuIcon,
    VerticalDotsIcon,
    MenuBurgerIcon,
    RefreshIcon,
    GridIcon,
    BoomIcon,
    PortraitIcon,
    ShopIcon,
    ShoppingCartIcon,
    AngleSmallLeftIcon,
    AngleDoubleSmallLeftIcon,
    AngleDoubleSmallRightIcon,
    AngleSmallRightIcon,
    UndoIcon,
    SquareIcon,
    SearchIcon,
    FolderIcon,
    AddFolderIcon,
    HelpIcon,
    InboxIcon,
    CloudIcon,
    BellIcon,
    ExpandIcon,
    PictureIcon,
    CursorFingerIcon,
    InfoIcon,
    ShieldExclamationIcon,
    ExclamationIcon,
};
