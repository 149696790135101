import React from "react";
import styled from "styled-components";

import { StyledButtonPropsType as BasePropsType } from "../StyledButton";
import { ICONS } from "../StyledCard/Icons";
import { StyledDangerButton } from "../StyledDangerButton";

export type StyledDangerButtonWithIconPropsType = {
    icon: string;
    iconPosition: "right" | "left";
} & BasePropsType;

const StyledDangerButtonWithIconWrapper = styled(
    StyledDangerButton
)<StyledDangerButtonWithIconPropsType>`
    display: flex;
    align-items: center;
    padding: 12px 10px;
    gap: 10px;

    ${({ iconPosition }: StyledDangerButtonWithIconPropsType) =>
        iconPosition === "right"
            ? `flex-direction: row-reverse; justify-content: space-between;`
            : `flex-direction: unset;justify-content: start;`}

    .icon__clz {
        fill: ${({ outline }: BasePropsType) => (outline ? "#E30613" : "#fff")};
    }

    &:hover {
        .icon__clz {
            fill: #fff;
        }
    }
`;

export function StyledDangerButtonWithIcon({
    icon,
    children,
    variant,
    outline,
    rounded,
    iconPosition,
    disabled,
    block,
}: StyledDangerButtonWithIconPropsType) {
    const Icon: any = ICONS[icon];

    return (
        <StyledDangerButtonWithIconWrapper
            variant={variant}
            outline={outline}
            rounded={rounded}
            iconPosition={iconPosition}
            icon={icon}
            disabled={disabled}
            block={block}
        >
            {icon ? (
                <Icon
                    className="icon__clz"
                    fill="white"
                    height={20}
                    width={20}
                />
            ) : null}
            <span>{children}</span>
        </StyledDangerButtonWithIconWrapper>
    );
}
