import React from "react";
import { ButtonProps } from "reactstrap";
import styled from "styled-components";
import { BiCheck } from "react-icons/bi";

import { StyledButton } from "../StyledButton";
import { COLORS } from "../../stories/Colors/colors";

import "bootstrap/dist/css/bootstrap.min.css";

type Step = "valid" | "in progress" | "waiting";

export type StepButtonProps = {
    step: Step;
    stepTitle: string;
    stepNumber: number;
    stepDetail: string;
    rounded: boolean;
} & ButtonProps;

const StepButton = styled(StyledButton)<StepButtonProps>`
    background: ${({ step }: StepButtonProps) =>
        step === "waiting" ? "transparent" : COLORS.principal};
    color: ${({ step, color }: StepButtonProps) =>
        step === "waiting"
            ? color === "white"
                ? COLORS.principal
                : color
            : color};

    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 16px;
    border: 1px solid;
    border-color: ${({ step, color }: StepButtonProps) => {
        return step === "waiting"
            ? color === "white"
                ? COLORS.principal
                : color
            : COLORS.principal;
    }};
    ${({ step }: StepButtonProps) =>
        step === "in progress" ? "opacity: 0.84;" : ""}
    ${({ rounded }: StepButtonProps) =>
        rounded ? "border-radius: 6px;" : "border-radius: 0;"}

   &:hover {
        opacity: ${({ step }: StepButtonProps) =>
            step === "in progress" ? "0.75;" : " 0.85;"};

        background: ${({ step }: StepButtonProps) =>
            step === "waiting" ? "transparent" : COLORS.principal};
        border-color: ${({ step, color }: StepButtonProps) => {
            return step === "waiting"
                ? color === "white"
                    ? COLORS.principal
                    : color
                : color;
        }};

        color: ${({ step, color }: StepButtonProps) =>
            step === "waiting"
                ? color === "white"
                    ? COLORS.principal
                    : color
                : color};

        .divider_clz {
            opacity: 0.85;
        }
    }

    justify-content: start;

    @media (min-width: 576px) {
        .main-content__clz {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }

    .divider_clz {
        display: inline-block;
        width: 1px;
        background: ${({ step, color }: StepButtonProps) =>
            step === "waiting"
                ? color === "white"
                    ? COLORS.principal
                    : color
                : color};
        height: 1.5em;
        margin: 0px !important;
    }
`;

export function StyledStepButton({
    stepTitle,
    stepNumber,
    stepDetail,
    step,
    rounded,
    color,
    ...props
}: StepButtonProps) {
    return (
        <StepButton {...props} color={color} step={step} rounded={rounded}>
            <div className="main-content__clz">
                <div
                    style={{
                        display: "flex",
                        columnGap: "3px",
                        fontWeight: "500",
                        textAlign: "start",
                    }}
                >
                    <span>{stepTitle}</span>
                    <span>{stepNumber}</span>
                </div>
                <span className="divider_clz d-none d-sm-block" />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px",
                        textAlign: "start",
                    }}
                >
                    <span>{stepDetail}</span>
                    {step === "valid" ? (
                        <BiCheck
                            style={{
                                height: "2em",
                                width: "2em",
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </StepButton>
    );
}
