import React from "react";
import styled from "styled-components";
import Avatar from "react-avatar";

import { ICONS } from "./icons";

import "boxicons/css/boxicons.min.css";
import "font-awesome/css/font-awesome.min.css";

export type HeaderPropsType = {
    search: boolean;
    brand: string;
    icons: string[];
};

const HeaderWrapper = styled.header`
    z-index: 1002;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    width: 100%;
    .navbar-header {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        height: 70px;
        padding: 0 calc(24px / 2) 0 0;
        background-color: white;
    }
    .navbar-brand-box {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 250px;
        background: #292929;
        height: 70px;
    }
    .form-control {
        display: block;
        width: 100%;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border: none;
        height: 38px;
        padding-left: 40px;
        padding-right: 20px;
        background-color: #f3f3f9;
        box-shadow: none;
        border-radius: 30px;
    }
    .btn:focus {
        outline: 0;
        box-shadow: none;
    }
`;

function SearchBox() {
    return (
        <div className="app-search p-0 mx-2">
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                />
            </div>
        </div>
    );
}

export function Header({ brand, search, icons }: HeaderPropsType) {
    return (
        <HeaderWrapper>
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    {brand ? (
                        <React.Fragment>
                            <div className="navbar-brand-box mr-2">
                                <p className="mb-0" style={{ color: "white" }}>
                                    {brand}
                                </p>
                            </div>
                            <button
                                type="button"
                                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                            >
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </React.Fragment>
                    ) : null}
                    {search ? <SearchBox /> : null}
                </div>
                <div className="d-flex align-items-center">
                    <button
                        type="button"
                        className="btn btn-sm px-1"
                        style={{ fontSize: "30px" }}
                    >
                        <i className="bx bx-cloud" />
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm px-1"
                        style={{ fontSize: "30px" }}
                    >
                        <i className="bx bx-bell" />
                    </button>
                    {icons
                        ? icons.map((element: string, index: number) => (
                              <button
                                  key={index}
                                  type="button"
                                  className="btn btn-sm px-1"
                                  style={{ fontSize: "30px" }}
                              >
                                  <i className={ICONS[element]} />
                              </button>
                          ))
                        : null}

                    <Avatar className="mr-2" name="John Doe" size="55" round />
                </div>
            </div>
        </HeaderWrapper>
    );
}
